nav {
  font-weight: bold;
  background: black;
  color: white;
  padding: 1em 0;

  .container {
    display: flex;
    justify-content: space-between;
  }

  a {
    color: white;
    text-decoration: none;
  }

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-left: 2em;
    }

    @media (--mobile-viewport) {
      transition-duration: 0.5s;
      transform: translateY(-200px);
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      flex-direction: column;

      li {
        margin-bottom: 1em; 
      }
    }
  }

  &.open {
    position: fixed;
    z-index: 2;
    width: 100%;
    
    ul {
      transform: translateY(0);
      opacity: 1;
      pointer-events: fill;
    }
  }

  --menu-width: 40px;
  --menu-height: 16px;
  --menu-line-height: 2px;
  --menu-line-rotation: 20deg;

  .menu {
    display: none;
    position: absolute;
    top: 1em;
    right: 1em;
    width: var(--menu-width);
    height: var(--menu-height);

    @media (--mobile-viewport) {
      display: block;
    }

    .icon-left,
    .icon-right {
      transition-duration: 0.5s;
      position: absolute;
      left: 0;
      width: calc(var(--menu-width) / 2);
      height: var(--menu-line-height);
      top: calc(var(--menu-height) / 2);
      background-color: white;

      &::before {
        transition-duration: 0.5s;
        position: absolute;
        width: calc(var(--menu-width) / 2);
        height: var(--menu-line-height);
        background-color: white;
        content: "";
        top: calc(var(--menu-height) / -2);
      }

      &::after {
        transition-duration: 0.5s;
        position: absolute;
        width: calc(var(--menu-width) / 2);
        height: var(--menu-line-height);
        background-color: white;
        content: "";
        top: calc(var(--menu-height) / 2);
      }
    }

    .icon-right {
      left: calc(var(--menu-width) / 2);
    }

    &.open {
      .icon-left {
        transition-duration: 0.5s;
        background: transparent;
        
        &:before{
          transform: rotateZ(var(--menu-line-rotation)) scaleX(1.4) translate(4px, 4px);
        }
  
        &:after{
          transform: rotateZ(calc(var(--menu-line-rotation) * -1)) scaleX(1.4) translate(4px, -4px);
        }
      }
      
      .icon-right {
        transition-duration: 0.5s;
        background: transparent;  
        
        &:before{
          transform: rotateZ(calc(var(--menu-line-rotation) * -1)) scaleX(1.4) translate(-4px, 4px);
        }
  
        &:after{
          transform: rotateZ(var(--menu-line-rotation)) scaleX(1.4) translate(-4px, -4px);
        }
      }
    }
  }
}

header {
  background: black;
  color: white;
  overflow: auto;

  @media (--mobile-viewport) {

  }

  ul {
    @media (--mobile-viewport) {
      top: 0;
      left: 0;
      width: 100%;
      background: black;
      border-bottom: solid 1px white;
      padding-top: 2em;
      padding-bottom: 2em;

      li {
        margin-left: 3em;
      }
    }
  }

  h1 {
    margin-top: 3em;

    @media (--mobile-viewport) {
      margin-top: 20vh;
    }
  }

  p {
    display: block;
    margin-bottom: 5em;
    max-width: 560px;
    font-size: 0.875em;

    @media (--mobile-viewport) {
      font-size: 1em;
    }
  }
}

.headerExtension {
  height: 80vh;
  min-height: 400px;
  overflow: hidden;
}

.headerExtension {
  position: relative;

  .heroVideo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
  }

  .introText {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    color: white;
    padding-bottom: 2em;
    padding-top: 4em;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);


  }
}