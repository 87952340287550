@font-face {
  font-family: "Messapia";
  src: url("../fonts/Messapia-Regular.woff2") format("woff2"),
        url("../fonts/Messapia-Regular.woff") format("woff");
}
  
@font-face {
  font-family: "BioRhyme";
  font-weight: regular;
  src: url("../fonts/BioRhyme-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "BioRhyme";
  font-weight: bold;
  src: url("../fonts/BioRhyme-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "BioRhyme";
  font-weight: light;
  src: url("../fonts/BioRhyme-Light.ttf") format("ttf");
}

@font-face {
  font-family: "Messapia";
  src: url("../fonts/Messapia-Regular.woff2") format("woff2"),
        url("../fonts/Messapia-Regular.woff") format("woff");
}

@custom-media --mobile-viewport (max-width: 767px);

@custom-media --small-viewport (max-width: 1024px);

@custom-media --medium-viewport (max-width: 1240px);

body {
  margin: 0;
  font-family: "Messapia";
  font-size: 18px;
  line-height: 140%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection { background: yellow; color: black;}

h1, h2, h3 {
  margin-top: 0;
  font-weight: bold;
  line-height: 1em;
}

h1 {
  font-size: 3em;

  @media (--medium-viewport) { 
    font-size: 2em;
  }
}

p {
  font-family: "BioRhyme";
}

img {
    display: block;
    width: 100%;
}

figure {
    margin: 0;
}

.blur-up {
  filter: blur(20px);
  transition: filter 400ms, -webkit-filter 400ms;
}

.blur-up.lazyloaded {
  filter: blur(0);
}

.no-js img.lazyload {
  display: none;
}


button {
  border: none;
  border-radius: 0;
  background: black;
  color: white;
  padding: 1em 2em;
  font-family: "Messapia";
  font-weight: bold;
  cursor: pointer;
}