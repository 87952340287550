footer {
    padding: 4em 0; 
    background: black;
    color: white;
    overflow: auto;
    font-weight: bold;
  
    .container {
      display: flex;
      justify-content: space-between;
    }
  
    @media (--medium-viewport) {
      padding: 4em 4em 4em 0; 

    }

    @media (--small-viewport) {
      padding: 2em 2em 2em 0; 
    }
  
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  
    a {
      color: white;
      text-decoration: none;
    }
  }
  