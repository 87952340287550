@import "global.css";
@import "header.css";
@import "footer.css";
@import "contact.css";
@import "vita.css";
@import "workpreview.css";
@import "default-skin.css";
@import "artwork.css";
@import "exhibition.css";
@import "../../node_modules/photoswipe/dist/photoswipe.css";

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1em;
}

.workFeedParent {
  background: black;
}

.workFeed {
  padding-top: 2em; 
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 1em;
  background: black;

  @media (--mobile-viewport) {
    display: flex;
    flex-direction: column;
  }
}

.spatialOverlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  transform: scale(1);
  opacity: 1;
  transition: all 0.45s ease-in-out;

  &.hidden {
    pointer-events: none;
    transform: translate(0, 0vh) scale(0.6);
    transition: all 0.25s ease-out;
    opacity: 0;
  }

  .spatialOverlayControls {
    position: absolute;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}