.vita {
    position: relative;
    margin-top: 6em;
    margin-bottom: 8em;

    @media (--mobile-viewport) {
        margin-top: 3em;
        margin-bottom: 0em;
    }

    img {
        max-width: 600px;

        @media (--medium-viewport) {
            max-width: 500px;
        }
    }

    ul {
        position: absolute;
        top: 2em;
        left: 45%;
        padding: 2em 2em 2em 4em;
        background: black;
        color: white;

        @media (--mobile-viewport) {
            left: 1em;
            top: -9em;
            max-width: 75%;
            position: relative;
            padding: 2em 2em 2em 2em;

            span {
                font-size: 0.75em;
            }
        }

        span {
            width: 12%;
        }

        li {
            display: flex;
            margin-bottom: 3em;

            p {
                margin: 0 0 0 2em;
            }
        }
    }
}