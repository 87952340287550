.workPreview {
    margin-top: 4em;
    margin-bottom: 2em;
    overflow: auto;
    clear: both;
    transition: all 0.35s ease-in;

    &:hover{
      color: white;

      figure.preview {
        background: rgba(255,255,255,0.1);
      }
    }

  
    @media (--mobile-viewport) {
      margin-top: 0;
    }

    .description {
      text-align: center;

      h2 {
        font-size: 2em;
      }
    }
  
    figure {
      width: 100%;
      margin-bottom: 0.5em;
      display: flex;
      justify-content: center;
      padding: 1em 0;
      transition: all 0.35s ease-in;


      &.preview {
        height: 400px;
        overflow: hidden;
        background: rgba(0,0,0,0.05);

        img {
          height: 100%;
          width: auto;
        }
      }
    }
  
    h2 {
      margin-bottom: 0;
    }
  
    p {
      margin-top: 0.5em;
    }
  
    .availability {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: #B6E4AE;
  
      &.unavailable {
        background: #DC7676;
      }

      &.reserved {
        background: #ffc107;
      }
    }
  
  }