.container.artwork {
    padding-top: 2em;
    padding-bottom: 5em;
    text-align: center;

    img {
        margin-bottom: 8px;
    }
}

.artwork {
    figure {
        margin: 0;
    }
}

.workNavigation {
    background: transparent;
    text-align: center;
    margin-bottom: 5em;
    
    a {
        color: black;
    }
}

.additional {
    display: flex;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 1em;
    gap: 8px;

    a {
        
        max-width: 150px;

        img {
            min-height: 100px;
            max-height: 140px;
            width: auto;
        }
    }
}

.artworkHeroStage{
    display: block;
    position: relative;
    height: 80vh;
    min-height: 500px;
    background: rgba(0,0,70,0.05);
    padding-top: 10vh;

    figure, a {
        position: relative;
        height: 100%;
        max-width: 100%;
    }

    img {
        position: relative;
        object-fit: contain;
        z-index: 2;
        margin: auto;
        max-width: 80%;
        max-height: 90%;
        min-height: 80%;
        width: auto;
        vertical-align: middle;
        transition: all .6s ease;
    }
}