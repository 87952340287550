.honeypot {
    position: absolute;
    left: -9999px;
}

.contactform {
    min-height: 50vh;

    form {
        overflow: auto;
        margin-top: 2em;
        margin-bottom: 6em;
        padding: 3em 1em 3em 1em;

        .field {
            display: flex;
            align-content: stretch;
            margin-bottom: 2em;

            @media (--small-viewport) {
                display: block;
            }

            abbr {
                text-decoration: none;
                font-weight: normal;
            }

            label {
                font-weight: bold;
                display: block;
                width: 30%;

                @media (--small-viewport) {
                    width: 100%;
                }
            }

            input, textarea {
                width: 70%;
                border: none;
                border-bottom: solid 2px black;
                font-family: "Messapia";
                font-size: 1.25em;

                @media (--small-viewport) {
                    width: 100%;
                }
            }
        }

        button {
            float: right;

            @media (--small-viewport) {
                width: 100%;
            }
        }
    }
}