.exhibition {
    position: relative;
    overflow: hidden;
    padding: 1em;
    margin-top: 4em;
    border: solid 10px white;
    transition: all 0.45s ease;
    
    a {
        color: white;
        text-decoration: none;
        z-index: 1;
        transition: all 0.45s ease;
    }

    span {
        display: block;
        margin-bottom: 5em;
        z-index: 1;
        position: relative;
    }

    h2 {
        font-size: 2em;
        margin-bottom: 0.5em;
        z-index: 1;
        position: relative;
    }

    button, h3 {
        z-index: 1;
        position: relative;
    }

    button {
        background: white;
        color: black;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        transition: all 0.45s ease;

        @media (--mobile-viewport) {
            width: auto;
            height: 100%;
            right: -8em;
            bottom: 0;
            top: auto;
            left: auto;
        }
    }

    a:hover {
        color: black;

        img {
            opacity: 0;
        }

        button {
            background: black;
            color: white;
        }
    }

    &:hover {
        background: white;
    }
}

.exhibtionDetail {
    background: #030406;
    color: white;

    .heroArea {
        min-height: 80vh;
        background-position: top center;
        background-size: cover;
    }


    .keyDates {
        text-align: center;
        padding: 0 1em;
        padding-top: 60vh;

        h1 {
            span {
                display: block;
                font-size: 0.5em;
            }
        }

        h4 {
            margin-bottom: 0;
        }

        p {
            margin-top: 0;
        }

        button {
            background: white;
            color: black;

            @media (--mobile-viewport) {
                width: 100%;
                font-size: 0.8em;
            }
        }
    }

    .details {
        margin-top: 4em;
        padding-bottom: 5em;
        max-width: 1024px;
        border-bottom: 1px solid white;

        hr {
            margin-top: 2em;
        }
        
        .extraTimes {
            font-size: 0.7em;
        }

        .bandDescription {
            font-size: 1.5em;
            line-height: 1.3em;

            a {
                color: white;
            }

            @media (--mobile-viewport) {
                font-size: 1.1em;
                line-height: 1.3em; 
            }
        }

        iframe {
            border: solid 4px white;
        }

        .bandLinks button{
            margin: 2px 0;
            border: solid 1px white;
        }
    }
}